<template>
  <Popover class="relative" style="border-bottom: 1px solid #13316b">
    <div class="mx-auto max-w-7xl px-4 sm:px-6">
      <div
        class="flex items-center justify-between border-b-1 border-gray-100 dark:border-slate-700 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link :to="{ name: 'home' }">
            <span class="sr-only">{{ appName }}</span>
            <img width="140" src="/images/logo_dark.png" />
          </router-link>
        </div>
        <div class="-my-2 -mr-2 md:hidden">
          <PopoverButton
            class="inline-flex items-center justify-center rounded-md bg-base p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:bg-slate-800 dark:hover:bg-slate-800"
            @click="openDrop"
          >
            <span class="sr-only">Open menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden space-x-10 md:flex">
          <router-link
            v-if="authenticated && roles && (roles.admin || roles.superAdmin)"
            v-slot="{ isActive }"
            :to="{ name: 'admin' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-400"
            @click="closeDrop"
          >
            <span
              :class="[
                isActive &&
                  'cursor-default text-white hover:text-gray-900 dark:text-white dark:hover:text-gray-500',
              ]"
            >
              <BuildingLibraryIcon class="float-left mr-2 h-6 w-6" />
            </span>
          </router-link>

          <router-link
            :to="{ name: 'home' }"
            class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
          >
            {{ $t('home') }}
          </router-link>
          <!-- <router-link
            :to="{ name: 'staking' }"
            class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
          >
            {{ $t('staking') }}
          </router-link> -->

          <router-link
            :to="{ name: 'faq' }"
            class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
          >
            {{ $t('faq') }}
          </router-link>

          <!-- <router-link
            :to="{ name: 'download' }"
            class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
          >
            {{ $t('download') }}
          </router-link> -->

          <a
            href="https://download.openverse.network"
            class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
            target="_blank"
          >
            {{ $t('download') }}
          </a>

          <!-- <a
            href="mailto:service@openverse.network"
            class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
          >
            {{ $t('support') }}
          </a> -->
        </PopoverGroup>
        <div
          v-if="authenticated && user"
          class="hidden items-center justify-end md:flex md:flex-1 lg:w-0"
        >
          <AppButton
            v-if="isImpersonating"
            v-tippy="'Return to your account'"
            icon="fa-solid fa-user-secret"
            class="float-right mr-4 h-3 w-3 rounded"
            style="background: transparent !important"
            @click="closeDrop"
          />

          <div ref="dropMenu" class="relative">
            <div
              class="cursor-pointer items-center p-3 text-base font-medium tracking-wider text-gray-200 hover:text-white dark:text-gray-300 dark:hover:text-white"
              @click="drop = !drop"
            >
              {{
                user && user.address
                  ? user.address.substr(0, 7) + '...' + user.address.substr(38)
                  : ''
              }}
              <UserCircleIcon class="float-right ml-2 mt-0 h-6 w-6" />
            </div>

            <div
              v-show="drop"
              class="absolute right-5 z-10 flex w-auto flex-col whitespace-nowrap rounded border shadow-md main_bg"
              @click="drop = !drop"
            >
              <router-link
                v-if="
                  authenticated && roles && (roles.admin || roles.superAdmin)
                "
                v-slot="{ isActive }"
                :to="{ name: 'admin' }"
              >
                <span
                  class="flex items-center rounded-t p-4 pr-10 pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                      : 'text-gray-700'
                  "
                >
                  <BuildingLibraryIcon class="mr-2 h-6 w-6" />
                  Admin
                </span>
              </router-link>
              <router-link
                v-if="authenticated"
                v-slot="{ isActive }"
                :to="{ name: 'dashboard' }"
              >
                <span
                  class="flex items-center p-4 pr-10 text-white pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? roles && (roles.admin || roles.superAdmin)
                        ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                        : 'cursor-default whitespace-nowrap rounded-t bg-slate-500 text-white'
                      : roles && (roles.admin || roles.superAdmin)
                        ? 'text-white'
                        : 'rounded-t text-white'
                  "
                >
                  <HomeIcon class="mr-2 h-6 w-6" />
                  {{ $t('dashboard') }}
                </span>
              </router-link>

              <router-link
                v-if="authenticated"
                v-slot="{ isActive }"
                :to="{ name: 'donation' }"
              >
                <span
                  class="flex items-center p-4 pr-10 pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                      : 'text-gray-400'
                  "
                >
                  <CircleStackIcon class="mr-2 h-6 w-6" />
                  {{ $t('my_donations') }}
                </span>
              </router-link>

              <router-link
                v-if="authenticated"
                v-slot="{ isActive }"
                :to="{ name: 'WalletBitgold' }"
              >
                <span
                  class="flex items-center p-4 pr-10 pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                      : 'text-gray-400'
                  "
                >
                  <CloudArrowUpIcon class="mr-2 h-6 w-6" />
                  {{ $t('my_wallet_bitgold') }}
                </span>
              </router-link>
              <router-link
                v-if="authenticated && user.usdt_rate > 0"
                v-slot="{ isActive }"
                :to="{ name: 'WalletUSDT' }"
              >
                <span
                  class="flex items-center p-4 pr-10 pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                      : 'text-gray-400'
                  "
                >
                  <ChartBarIcon class="mr-2 h-6 w-6" />
                  {{ $t('my_wallet_usdt') }}
                </span>
              </router-link>

              <router-link
                v-if="
                  authenticated && (user.usdt_rate > 0 || user.bitgold_rate > 0)
                "
                v-slot="{ isActive }"
                :to="{ name: 'preacher' }"
              >
                <span
                  class="flex items-center p-4 pr-10 pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                      : 'text-gray-400'
                  "
                >
                  <ChartBarIcon class="mr-2 h-6 w-6" />
                  {{ $t('my_preacher_center') }}
                </span>
              </router-link>

              <router-link
                v-if="authenticated"
                v-slot="{ isActive }"
                :to="{ name: 'friends' }"
              >
                <span
                  class="flex items-center p-4 pr-10 pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                      : 'text-gray-400'
                  "
                >
                  <CloudArrowUpIcon class="mr-2 h-6 w-6" />
                  {{ $t('my_friends') }}
                </span>
              </router-link>

              <!-- <router-link
                v-if="authenticated"
                v-slot="{ isActive }"
                :to="{ name: 'profile' }"
              >
                <span
                  class="flex items-center p-4 pr-10 pl-8 hover:bg-slate-800 hover:text-white"
                  :class="
                    isActive
                      ? 'cursor-default whitespace-nowrap bg-slate-500 text-white'
                      : 'text-gray-400'
                  "
                >
                  <CogIcon class="mr-2 h-6 w-6" />
                  {{ $t('settings') }}
                </span>
              </router-link> -->

              <div
                class="flex cursor-pointer items-center rounded-b p-4 pr-10 pl-8 text-gray-400 hover:bg-slate-800 hover:text-white"
                @click.prevent="logout(), closeDrop()"
              >
                <ArrowLeftOnRectangleIcon
                  class="mr-2 h-6 w-6"
                ></ArrowLeftOnRectangleIcon>

                {{ $t('logout') }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!authenticated"
          class="hidden items-center justify-end md:flex md:flex-1 lg:w-0"
        >
          <router-link :to="{ name: 'login' }">
            <AppButton
              btn-class="uppercase leading-snug inline-block rounded bg-blue-600 px-7 py-3 text-sm font-medium uppercase leading-snug text-white shadow-md transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-lg focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg "
            >
              <template #text>
                <span> Web3 {{ $t('login') }} </span>
              </template>
            </AppButton>
          </router-link>
          <!-- &nbsp;
          <router-link :to="{ name: 'LoginEmail' }">
            <AppButton
              btn-class="uppercase leading-snug inline-block rounded bg-blue-600 px-7 py-3 text-sm font-medium uppercase leading-snug text-white shadow-md transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-lg focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg "
            >
              <template #text>
                <span> {{ $t('email') }} {{ $t('login') }} </span>
              </template>
            </AppButton>
          </router-link> -->
        </div>
      </div>
    </div>
    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        v-show="drop"
        v-slot="{ close }"
        focus
        style="z-index: 100"
        class="absolute bt-red inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
      >
        <div
          class="divide-y-2 divide-gray-50 rounded-lg bg-purple shadow-lg ring-1 ring-black ring-opacity-5 dark:divide-gray-700 dark:bg-slate-900"
        >
          <div class="px-5 pt-5 pb-6">
            <div class="flex items-center justify-between">
              <div>
                <!-- <img class="h-8 w-auto" :src="logo" :alt="appName" /> -->
                <span class="sr-only">{{ appName }}</span>

                <router-link
                  v-slot="{ isActive }"
                  :to="{ name: 'home' }"
                  @click="closeDrop"
                >
                  <img width="36" src="/images/icon.png" />
                </router-link>
              </div>
              <div class="-mr-2">
                <PopoverButton
                  class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:bg-slate-800"
                >
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="space-y-6 py-6 px-5">
            <div>
              <div class="p-2">
                <router-link
                  :to="{ name: 'home' }"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                  @click="closeDrop"
                >
                  {{ $t('home') }}
                </router-link>
              </div>

              <!-- <div class="p-2">
                <router-link
                  :to="{ name: 'staking' }"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                  @click="closeDrop"
                >
                  {{ $t('staking') }}
                </router-link>
              </div> -->

              <div class="p-2">
                <router-link
                  :to="{ name: 'faq' }"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                  @click="closeDrop"
                >
                  {{ $t('faq') }}
                </router-link>
              </div>

              <!-- <div class="p-2">
                <router-link
                  :to="{ name: 'mapping' }"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                >
                  {{ $t('mapping') }}
                </router-link>
              </div> -->
              <!-- <div class="p-2">
                <router-link
                  :to="{ name: 'guide' }"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                >
                  {{ $t('guide') }}
                </router-link>
              </div> -->
              <div class="p-2">
                <a
                  href="https://download.openverse.network"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                  target="_blank"
                >
                  {{ $t('download') }}
                </a>
                <!-- <router-link
                  :to="{ name: 'download' }"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                  @click="closeDrop"
                >
                  {{ $t('download') }}
                </router-link> -->
              </div>
              <!-- <div class="p-2">
                <a
                  href="mailto:service@openverse.network"
                  class="text-base font-medium text-gray-500 hover:text-white dark:text-gray-300 dark:hover:text-white"
                  @click="closeDrop"
                >
                  {{ $t('support') }}
                </a>
              </div> -->
            </div>
            <div class="">
              <div
                v-if="
                  authenticated && roles && (roles.admin || roles.superAdmin)
                "
                class="mb-6 text-left"
              >
                <router-link
                  v-slot="{ isActive }"
                  :to="{ name: 'admin' }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <HomeIcon class="float-left mr-2 h-6 w-6" /> Admin
                  </span>
                </router-link>
              </div>

              <div v-if="authenticated" class="mb-6 text-left">
                <router-link
                  v-slot="{ isActive }"
                  :to="{ name: 'dashboard' }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <HomeIcon class="float-left mr-2 h-6 w-6" />
                    {{ $t('my_dashboard') }}
                  </span>
                </router-link>
              </div>

              <div v-if="authenticated" class="mb-6 text-left">
                <router-link
                  v-slot="{ isActive }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  :to="{ name: 'donation' }"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <CogIcon class="float-left mr-2 h-6 w-6" />
                    {{ $t('my_donations') }}
                  </span>
                </router-link>
              </div>

              <div v-if="authenticated" class="mb-6 text-left">
                <router-link
                  v-slot="{ isActive }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  :to="{ name: 'WalletBitgold' }"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <CloudArrowUpIcon class="float-left mr-2 h-6 w-6" />

                    {{ $t('my_wallet_bitgold') }}
                  </span>
                </router-link>
              </div>

              <div
                v-if="authenticated && user.usdt_rate > 0"
                class="mb-6 text-left"
              >
                <router-link
                  v-slot="{ isActive }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  :to="{ name: 'WalletUSDT' }"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <CloudArrowUpIcon class="float-left mr-2 h-6 w-6" />

                    {{ $t('my_wallet_usdt') }}
                  </span>
                </router-link>
              </div>

              <div class="mb-6 text-left">
                <router-link
                  v-if="
                    authenticated &&
                    (user.usdt_rate > 0 || user.bitgold_rate > 0)
                  "
                  v-slot="{ isActive }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  :to="{ name: 'preacher' }"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <ChartBarIcon class="float-left mr-2 h-6 w-6" />

                    {{ $t('my_preacher_center') }}
                  </span>
                </router-link>
              </div>

              <!-- <div v-if="authenticated" class="mb-6 text-left">
                <router-link
                  v-slot="{ isActive }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  :to="{ name: 'mappings' }"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <CogIcon class="float-left mr-2 h-6 w-6" />

                    {{ $t('my_mappings') }}
                  </span>
                </router-link>
              </div> -->

              <div v-if="authenticated" class="mb-6 text-left">
                <router-link
                  v-slot="{ isActive }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  :to="{ name: 'friends' }"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <CogIcon class="float-left mr-2 h-6 w-6" />

                    {{ $t('my_friends') }}
                  </span>
                </router-link>
              </div>

              <div class="mb-7 text-left">
                <router-link
                  v-if="authenticated"
                  v-slot="{ isActive }"
                  :to="{ name: 'password' }"
                  class="text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  @click="closeDrop"
                >
                  <span
                    :class="[
                      isActive &&
                        'text-gray-800 hover:text-gray-900 dark:text-gray-600',
                    ]"
                  >
                    <CogIcon class="float-left mr-2 h-6 w-6" />
                    {{ $t('settings') }}
                  </span>
                </router-link>
              </div>

              <div
                v-if="isImpersonating && authenticated"
                class="mb-8 text-left"
                style="margin-top: -0.5em"
              >
                <div
                  class="cursor-pointer text-base font-medium text-gray-500 hover:text-gray-900 dark:hover:text-gray-200"
                  @click="leaveImpersonating(), closeDrop()"
                >
                  <span class="fa-solid fa-user-secret fa-fw mr-2 ml-1" />
                  Return to your account
                </div>
              </div>
            </div>
            <div v-if="!authenticated">
              <router-link :to="{ name: 'login' }">
                <AppButton
                  btn-class="uppercase leading-snug inline-block rounded bg-blue-600 px-7 py-3 text-sm font-medium uppercase leading-snug text-white shadow-md transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-lg focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg "
                  @click="closeDrop()"
                >
                  <template #text>
                    <span> Web3{{ $t('login') }} </span>
                  </template>
                </AppButton>
              </router-link>
              <!-- &nbsp;
              <router-link :to="{ name: 'LoginEmail' }">
                <AppButton
                  btn-class="uppercase leading-snug inline-block rounded bg-blue-600 px-7 py-3 text-sm font-medium uppercase leading-snug text-white shadow-md transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-lg focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg "
                  @click="closeDrop()"
                >
                  <template #text>
                    <span> {{ $t('email') }} {{ $t('login') }} </span>
                  </template>
                </AppButton>
              </router-link> -->
            </div>
            <div v-if="authenticated">
              <AppButton
                primary
                text="Logout"
                type="button"
                class="flex w-full items-center justify-center px-4 py-2"
                @click.prevent="logout(), closeDrop()"
              >
                <template #text>
                  <ArrowLeftOnRectangleIcon
                    class="mr-2 h-6 w-6"
                  ></ArrowLeftOnRectangleIcon>

                  {{ $t('logout') }}
                </template>
              </AppButton>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseDisplayDate } from '@services/common';
import CircleSvg from '@components/CircleSvg.vue';
import Web3 from 'web3';
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Switch,
} from '@headlessui/vue';
import {
  Bars3Icon,
  HomeIcon,
  BuildingLibraryIcon,
  InformationCircleIcon,
  DocumentTextIcon,
  XMarkIcon,
  ChartBarIcon,
  CloudArrowUpIcon,
  CircleStackIcon,
  CogIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline';

export default {
  name: 'AppNav',
  components: {
    HomeIcon,
    CircleSvg,
    BuildingLibraryIcon,
    InformationCircleIcon,
    DocumentTextIcon,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChartBarIcon,
    CloudArrowUpIcon,
    CircleStackIcon,
    Bars3Icon,
    XMarkIcon,
    CogIcon,
    ArrowLeftOnRectangleIcon,
    ChevronDownIcon,
    UserCircleIcon,
    Switch, // eslint-disable-line
  },
  props: {},
  data() {
    return {
      connectLoading: false,
      appName: 'Workflow',
      // logo: "https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600",
      drop: false,
      errors: null,
      success: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      roles: 'auth/roles',
      currentUserToken: 'auth/currentUserToken',
      impersonatorToken: 'auth/impersonatorToken',
    }),
    isImpersonating() {
      if (this.currentUserToken && this.impersonatorToken) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      updateTheme: 'auth/theme',
      popToast: 'toast/popToast',
      leaveImpersonatingUser: 'auth/leaveImpersonatingUser',
    }),
    parseDisplayDate,
    closeDrop() {
      this.drop = false;
    },
    openDrop() {
      this.drop = true;
    },
    async toggleTheme() {
      this.errors = null;
      this.success = '';
      try {
        await this.updateTheme({ theme_dark: !this.user.theme_dark }).then(
          (response) => {
            if (
              response &&
              response.data &&
              response.data.user &&
              response.data.user.id
            ) {
              this.popToast({
                message: 'Theme Saved',
                timer: 2000,
                icon: 'success',
              });
            }
          },
        );
      } catch (e) {
        this.errors = e.data;
        this.popToast({
          message: 'Error Updating Theme',
          timer: 5000,
          icon: 'error',
        });
      }
    },
    async leaveImpersonating() {
      try {
        await this.leaveImpersonatingUser().then((response) => {
          //
        });
      } catch (e) {
        this.popToast({
          message: 'Unable To Return To Yourself',
          timer: 5000,
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style scoped></style>
<style lang="scss" scoped></style>
